/* MAIN JS FILE */

console.log('MAIN JS LOADED');



/* Back to top */

function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

var btn = $('#back-top-block');

$(window).scroll(function() {
  if ($(window).scrollTop() > 300) {
    btn.addClass('show');
  } else {
    btn.removeClass('show');
  }
});

btn.on('click', function(e) {
  e.preventDefault();
  $('html, body').animate({scrollTop:0}, '300');
});





// Mobile Menu Icon 

jQuery('.menu-icon').click(function () {
    jQuery('.menu-icon').toggleClass('open');
});

jQuery(document).ready(function(){
  jQuery('.owl-carousel').owlCarousel({
    loop:true,
    autoplay:true,
    margin:0,
    nav:false,
    dots:true,
    responsive:{
        0:{
            items:1
        },
        600:{
            items:1
        },
        1000:{
            items:1
        }
    }
})
});

document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView({
            behavior: 'smooth'
        });
    });
});



jQuery(document).foundation();


